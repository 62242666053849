import { Box, Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import Logo from 'components/logo';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_PAGE } from 'routes/paths';

const LINKS = [
  {
    headline: 'FirstRow Sports',
    children: [
      { name: 'Blogs', href: PATH_PAGE.blogs },
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: PATH_PAGE.termsAndConditions },
      { name: 'Privacy Policy', href: PATH_PAGE.privacyPolicy },
    ],
  },
  {
    headline: 'Contact',
    children: [{ name: 'SportsFirstRow@gmail.com', href: '#' }],
  },
];

export default function Footer() {
  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container sx={{ pt: 5 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid
            item
            xs={8}
            md={5}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="center"
            gap={5}
          >
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Watch live sports matches from around the world on FirstRow Sports. Enjoy high-quality
              streams of your favorite sports events anytime, anywhere.
            </Typography>
          </Grid>

          <Grid item xs={12} md={7} mt={{ xs: 5, md: 0 }}>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: 'column', md: 'row' }}
            >
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={0.5}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      to={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="column">
          <Stack
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="center"
            mt={3}
            mb={3}
            gap={{ xs: 3, md: 0 }}
            justifyContent="space-between"
          >
            <Typography variant="caption" component="div">
              Copyright © 2024 FirstRow Sports. All rights reserved
            </Typography>
            <Box
              component="img"
              width={80}
              height="auto"
              src="/assets/images/dmc-image.png"
              alt="DMCA Logo"
              loading="lazy"
              mx="auto"
            />
          </Stack>
          <Typography
            variant="caption"
            component="div"
            sx={{
              pb: 5,
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            Disclaimer: This website does not host any of the videos referenced herein. Links to
            streams from external platforms such as YouTube are provided solely for user
            convenience. The website disclaims any responsibility for the legality of the content.
            All legal inquiries or issues should be addressed directly to the respective media file
            owners or hosting platforms.
          </Typography>
        </Grid>
      </Container>
    </Box>
  );

  return mainFooter;
}
